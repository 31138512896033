<template>
  <b-modal
    id="booking-detail-modal"
    title="Booking Detail Modal"
    centered
    hide-footer
    size="lg"
  >
    <template #modal-title>
      <h2 class="m-0">Booking Details</h2>
    </template>
    <b-row v-if="this.booking" style="font-size: 1rem !important">
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Name:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.name }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Contact Number:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.mobileNo }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Email:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.email }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="this.booking" style="font-size: 1rem !important">
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Age:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.age }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Country:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.country }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          State:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.state }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="this.booking" style="font-size: 1rem !important">
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Gender:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.gender }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          postal Code:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.postalCode }}
          </span>
        </p>
      </b-col>
      <b-col md="4">
        <p class="font-weight-normal text-dark">
          Procedure:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.treatment }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="this.booking" style="font-size: 1rem !important">
      <b-col md="12">
        <p class="font-weight-normal text-dark">
          Message:
          <span class="font-weight-bolder text-colorBlue">
            {{ this.message }}
          </span>
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="this.booking && this.booking.images"
      style="font-size: 1rem !important"
    >
      <p class="font-weight-normal text-dark px-1">Medical Reports:</p>
      <b-col
        md="12"
        class="mb-2"
        v-for="(document, index) in files"
        :key="index"
      >
        <b-button
          pill
          size="sm"
          variant="colorBlue"
          @click="openDocument(document.file)"
        >
          document {{ index + 1 }}
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="this.booking && this.booking.images.length === 0"
      style="font-size: 1rem !important"
    >
      <p class="font-weight-bolder text-colorBlue px-1">
        No Medical Reports Uploaded.
      </p>
    </b-row>
    <p>Service Selected</p>
    <b-row class="px-1 d-flex justify-content-start">
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.visa_assistance === true"
      >
        <b-badge variant="colorGreen"> Visa Assistance </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.tourism === true"
      >
        <b-badge variant="colorGreen"> Tourism </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.car_rental === true"
      >
        <b-badge variant="colorGreen"> Car Rental </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.hotel_booking === true"
      >
        <b-badge variant="colorGreen"> Hotel </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.insurance === true"
      >
        <b-badge variant="colorGreen"> Insurance </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.safe_travel === true"
      >
        <b-badge variant="colorGreen"> Safe Travel </b-badge>
      </b-col>
      <b-col
        md="2"
        class="text-center"
        v-if="this.booking && this.booking.interpreter_service === true"
      >
        <b-badge variant="colorGreen"> Interpreter Service </b-badge>
      </b-col>
    </b-row>
    <b-row
      class="px-1"
      v-if="
        this.booking &&
        this.booking.visa_assistance === false &&
        this.booking.hotel_booking === false &&
        this.booking.car_rental === false &&
        this.booking.tourism === false &&
        this.booking.insurance === false &&
        this.booking.safe_travel === false &&
        this.booking.interpreter_service === false
      "
    >
      <p class="font-weight-bolder text-colorBlue">no service selected</p>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    booking: Object,
  },
  data() {
    return {
      required,
      name: "",
      email: "",
      mobileNo: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
      treatment: "",
      treatments: [],
      gender: "",
      age: "",
      message: "",
      files: [],
    };
  },
  async mounted() {
    if (this.booking) {
      this.name = this.booking.full_name;
      this.email = this.booking.email;
      this.mobileNo = this.booking.contact_number;
      this.country = this.booking.country;
      this.state = this.booking.state;
      this.city = this.booking.city;
      this.treatment = this.booking.procedure;
      this.gender = this.booking.gender;
      this.age = this.booking.age;
      this.postalCode = this.booking.postal_code;
      this.message = this.booking.message;
      this.files = this.booking.images;
    }
  },
  methods: {
    ...mapActions({}),
    openDocument(path) {
      window.open(path, "_blank");
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
